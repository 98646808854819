import Vue from 'vue';
import Vuex from 'vuex';

import settings from './modules/settings';
import dictionaries from './modules/dictionaries';
import pageTemplates from './modules/pageTemplates';
import translates from './modules/translates';
import auth from './modules/auth';
import files from './modules/files';
import users from './modules/users';
import trips from './modules/trips';
import bookings from './modules/bookings';
import documents from './modules/documents';
import payments from './modules/payments';
import reviews from './modules/reviews';
import news from './modules/news';
import reports from './modules/reports';
import feedbacks from './modules/feedbacks';
import chats from './modules/chats';
import events from './modules/events';
import photos from './modules/photos';
import quiz from './modules/quiz';
import waitingLists from './modules/waitingLists';
import tripRequests from './modules/tripRequests';
import bloggerTrips from './modules/bloggerTrips';
import bloggerResources from './modules/bloggerResources';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        settings,
        dictionaries,
        pageTemplates,
        translates,
        auth,
        files,
        users,
        trips,
        bookings,
        documents,
        payments,
        reviews,
        news,
        reports,
        feedbacks,
        chats,
        events,
        photos,
        quiz,
        waitingLists,
        tripRequests,
        bloggerTrips,
        bloggerResources,
    }
})
