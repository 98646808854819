<template>
    <v-menu
        bottom
        left
        offset-y
        max-height="490px"
        max-width="380px"
        style="z-index: 100"
    >
        <template #activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on"
                class="desktop-header__user-events">
                <v-badge color="#e11a5a" :content="summary.created + summary.delivered" v-if="summary.created + summary.delivered > 0">
                    <img src="../../assets/bell-icon.svg">
                </v-badge>
                <img v-else src="../../assets/bell-icon.svg">
            </div>
        </template>
        <v-list v-if="user._id">
            <div class="notificaton-list">
                <div v-if="newMessages.length > 0" class="notificaton-list__item__outline">{{ translates[langUrl].notifications_new[lang] }}({{ newMessages.length }})</div>
                <template v-for="(event, index) in newMessages">
                    <div :key="'unreded event' + index" class="notificaton-list__item">
                        <div class="notificaton-list__item__image" :class="`notificaton-list__item__image__${event.icon}`"></div>
                        <div class="notificaton-list__item__text-container">
                            <div class="notificaton-list__item__title">{{ event.subject  }}</div>
                            <div class="notificaton-list__item__text">{{ event.message }}</div>
                            <a class="notificaton-list__item__link" v-if="event.link" :href="event.link">{{ event.linkText }}</a>
                        </div>
                    </div>
                </template>
                <div v-if="newMessages.length > 0" class="notificaton-list__item__outline">{{ translates[langUrl].notifications_readed[lang] }}</div>
                <template v-for="(event, index) in readedMessages">
                    <div :key="'reded' + index" class="notificaton-list__item">
                        <div class="notificaton-list__item__image" :class="`notificaton-list__item__image__${event.icon}`"></div>
                        <div class="notificaton-list__item__text-container">
                            <div class="notificaton-list__item__title">{{ event.subject  }}</div>
                            <div class="notificaton-list__item__text">{{ event.message }}</div>
                            <a class="notificaton-list__item__link" v-if="event.link" :href="event.link">{{ event.linkText }}</a>
                        </div>
                    </div>
                </template>
            </div>
        </v-list>
    </v-menu>
</template>

<script>
    import { imageSrc } from '@/helpers';
    import { mapState } from 'vuex';
    import store from '@/store';

    export default {
        name: 'NotificationList',
        components: {
        },
        props: {
            items: {
                type: Array, 
                default: () => []
            },
        },
        data: () => ({
            imageSrc,
            langUrl: 'Events'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('auth', ['user']),
            ...mapState('events', ['summary']),
            ...mapState('events', {
                events: state => state.entities
            }),
            readedMessages() {
                return this.events.filter(event => event.status === 'readed')
            },
            newMessages() {
                return this.events.filter(event => event.status === 'created')
            }
        },
        methods: {
            
        }
    }
</script>

<style lang="scss">
.notificaton-list {
    padding: 22px 20px 30px 32px;
    &__item {
        padding: 10px 0;
        display: flex;
        &__image {
            min-width: 44px;
            height: 44px;
            margin-right: 12px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            &__speaker {
                background-image: url('../../assets/notificationIcons/speaker.svg');
            }
            &__message {
                background-image: url('../../assets/notificationIcons/message.svg');
            }
            &__ok {
                background-image: url('../../assets/notificationIcons/ok.svg');
            }
            &__cancel {
                background-image: url('../../assets/notificationIcons/cancel.svg');
            }
        }
        &__text-container {
            font-family: 'Inter';
            font-style: normal;
        }
        &__title {
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            color: #273155;
        }
        &__text {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #616D89;
        }
        &__link {
            font-size: 14px;
            line-height: 150%;
        }
        &__outline {
            width: 100%;
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            color: #A1A4B1;
            margin-bottom: 10px;
            border-bottom: 1px #F6F7F9 solid;
            margin-bottom: 5px;
        }
    }
}
.v-menu__content::-webkit-scrollbar {
    display: none;
}
</style>
