export default {
    namespaced: true,

    state: {
        lang: process.env.VUE_APP_SITE_LANGUAGE,
        role: 'traveler'
    },

    mutations: {
        SET_ENTITY(state, payload) {
            state.role = payload;
        },
    },

    actions: {
    },

    getters: {}
}