import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from './plugins/axios';
import VueYandexMetrika from 'vue-yandex-metrika';
import './plugins/vue-meta';
import './plugins/vue-moment';
import './plugins/vee-validate';
import './plugins/vue-splide';
import './plugins/vue-youtube';
import './plugins/vue-yandex-maps';
import './plugins/vue-beautiful-chat';
import './plugins/vue-yandex-metrika';
import '@/styles/main.scss';

Vue.config.productionTip = false

new Vue({
    router,
    store,
    axios,
    vuetify,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
