import axios from '../../plugins/axios';
import moment from '@/plugins/moment';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {}
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {};
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null, limit = null, page = null }) {
            const { data } = await axios.get('/front/waiting-lists', { params: { filter, sorting, limit, page } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/front/waiting-lists/${ id }`);
            data.date = moment(data.date).format('YYYY-MM-DD');
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            await axios.post('/front/waiting-lists', state.entity);
        },
    },

    getters: {}
}
