<template>
    <div class="base-number-input" :class="{ 'base-number-input-error' : errorMessages }">
        <VuePhoneNumberInput
            v-bind="$attrs"
            v-on="$listeners"
            show-code-on-list
            color="#DA0A63"
            valid-color="#A9AEB2"
            :error="errorMessages !== ''"
            :translations="translations"
            :default-country-code="countryCode"
            :preferred-countries="preferredСountries"
            :countries-height="25"
        >
            <slot></slot>
        </VuePhoneNumberInput>
        <div class="v-text-field__details" v-if="errorMessages">
            <div class="v-messages theme--light error--text" role="alert">
                <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                        {{ errorMessages }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    
    export default {
        name: 'BasePhoneNumberInput',
        inheritAttrs: false,
        components: {
            VuePhoneNumberInput
        },
        props: {
            errorMessages: {
                type: String,
                default: ''
            }
        },
        computed: {
            ...mapState('settings', ['lang']),
            translations() {
                return {
                    phoneNumberLabel: this.lang === 'en' ? "Phone" : "Телефон"
                }
            },
            countryCode() {
                return this.lang === 'en' ? "US" : "RU";
            },
            preferredСountries() {
                return this.lang === 'en' ? ['US', 'GB', 'CA', 'MX', 'DE', 'UA'] : ['RU'];
            }
        },
        methods: {
        }
    }
</script>

<style lang="scss">
    .base-number-input {
        min-height: 56px !important;
        margin-bottom: 16px !important;

        .v-messages {
            margin-top: 4px;
        }
        label {
            display: none !important;
        }
        .input-tel {
            &__input {
                box-shadow: none !important;
                height: 56px !important;
                border: 1px solid #a8a8a8 !important;
                font-size: 18px !important;
                border-radius: 0 10px 10px 0 !important;
            }
            &.has-value .input-tel__input {
                padding-top: 0 !important;
                &::placeholder {
                    display: none !important;
                }
            }
            &__label {
                display: none !important;
            }
        }
        .country-selector {
            min-height: 56px !important;

            &__input {
                box-shadow: none !important;
                min-height: 56px !important;
                padding-top: 0 !important;
                font-size: 16px !important;
                border: 1px solid #a8a8a8 !important;
                border-radius: 10px 0 0 10px !important;
                &:hover {
                    border-color: #a8a8a8 !important;
                }
            }
            &__toggle {
                top: calc(50% - 11px) !important;
            }
            &__country-flag {
                height: 15px !important;
                display: flex !important;
                align-items: flex-end !important;
            }
        }
    }
    .base-number-input-error {
        .input-tel__input {
            border-color: #ff5252 !important;
        }
        .country-selector__input {
            border-color: #ff5252 !important;
        }
    }
</style>
