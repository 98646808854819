<template>
    <header class="header">
        <h1 class="header__title" @click="home()"></h1>
        <div class="header__buttons">
            <div
                v-if="user._id"
                class="header__user-events"
                @click="userGoTo('user-events')">
                <v-badge color="#e11a5a" :content="summary.created + summary.delivered" v-if="summary.created + summary.delivered > 0">
                    <img src="../../assets/bell-icon.svg">
                </v-badge>
                <img v-else src="../../assets/bell-icon.svg">
            </div>
            <div class="header__button">
                <v-menu 
                    bottom 
                    offset-y
                >
                    <template #activator="{ on, attrs }">
                        <div
                            class="header__button__wrap"
                            v-bind="attrs" 
                            v-on="on">
                            <img 
                                src="../../assets/Category.svg"
                                class="header__button__icon" />
                        </div>
                    </template>
                    <v-list class="header__menu">
                        <template v-if="user._id">
                            <v-list-item @click.stop="() => false">
                                <div class="header__full-menu-switch">
                                    <span>{{ { ru: 'Полное меню', en: 'Full menu' }[lang]  }}</span>
                                    <v-switch
                                        :input-value="fullMenu"
                                        @change="toggleFullMenu"
                                        hide-details
                                    />
                                </div>
                            </v-list-item>
                            <v-divider/>
                            <template v-for="link in menu.bloggerMenu.links">
                                <v-list-item v-if="user.blogger" :key="`main-menu-link-${link.link}`" @click="userGoToLink(link.link)">
                                    <v-list-item-title>{{ link[lang] }}</v-list-item-title>
                                </v-list-item>
                            </template>
                            <template v-for="link in menu.organizerMenu.links">
                                <v-list-item v-if="user.organizer" :key="`main-menu-link-${link.link}`" @click="userGoToLink(link.link)">
                                    <v-list-item-title>{{ link[lang] }}</v-list-item-title>
                                </v-list-item>
                            </template>
                            <v-list-item @click="userGoTo('user-profile')">
                                <v-list-item-title>{{ { ru: 'Профиль', en: 'Profile' }[lang]  }}</v-list-item-title>
                            </v-list-item>
                            <template v-if="fullMenu">
                                <template v-for="link in menu.hiddenMenu.links">
                                    <v-list-item :key="`main-menu-link-${link.link}`" @click="userGoToLink(link.link)">
                                        <v-list-item-title>{{ link[lang] }}</v-list-item-title>
                                    </v-list-item>
                                </template>
                            </template>
                            <v-list-item @click="logout">
                                <v-list-item-title>{{ { ru: 'Выйти', en: 'Logout' }[lang]  }}</v-list-item-title>
                            </v-list-item>
                        </template>
                        <template v-else>
                            <v-list-item @click="authorization">
                                <BaseButton class="button-fill header__menu__authorization">{{ { ru: 'Войти', en: 'Login' }[lang] }}</BaseButton>
                            </v-list-item>
                            <template v-for="(mkey, index) in ['mainMenuAbout', 'mainMenuBlogger', 'mainMenuOrganizer', 'mainMenuTrips']">
                                <v-expansion-panels :key="`main-menu-${index}`" accordion>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header @click.stop="() => false">
                                            {{ menu[mkey][lang] }}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <template v-for="link in menu[mkey].links">
                                                <v-list-item :key="`main-menu-link-${link.link}`" @click="goToLink(link.link, mkey)">
                                                    <v-list-item-title>{{ link[lang] }}</v-list-item-title>
                                                </v-list-item>
                                            </template>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </template>
                        </template>
                    </v-list>
                </v-menu>
            </div>
        </div>
    </header>
</template>

<script>
    import store from '@/store';
    import { TRIP_STATUS, TRIP_TYPE } from '@/vars';
    import { mapState } from 'vuex';
    import BaseButton from '../common/BaseButton'

    export default {
        name: 'Header',
        components: {
            BaseButton,
        },

        data: () => ({
            fullMenu: !!(+localStorage.getItem('fullMenu'))
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('pageTemplates', {
                menu: state => state.headerMenu
            }),
            ...mapState('auth', ['user']),
            ...mapState('events', ['summary']),
        },
        async mounted() {
            if(this.user._id) {
                await store.dispatch('events/summary');
                await store.dispatch('events/fetch', {});
            }
        },
        methods: {
            async home() {
                await this.$router.push({ name: 'home' });
            },
            toggleFullMenu() {
                this.fullMenu = !this.fullMenu;
                localStorage.setItem('fullMenu', +this.fullMenu);
            },
            async goToLink(link) {
                await this.$router.push(link);
            },
            async userGoTo(name) {
                if(this.user._id) {
                    await this.$router.push({ name });
                } else {
                    this.authorization()
                }
            },
            async userGoToLink(link) {
                if(this.user._id) {
                    await this.$router.push(link);
                } else {
                    this.authorization()
                }
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
                this.$root.$once('authorized', async () => {
                    if(this.user.blogger) {
                        await this.$router.push({ name: 'blogger-audience' });
                    }  
                });
            },
            async logout() {
                if(this.user._id) {
                    await store.dispatch('auth/logout');
                    store.commit('trips/CLEAR_ENTITIES');
                    store.commit('events/CLEAR_ALL');
                    await store.dispatch('trips/fetch', { filter: { type: TRIP_TYPE.TOUR_LEADER, status: TRIP_STATUS.ACTIVE } });
                    await this.$router.push({ name: 'home' });
                }
            },
            async openChat() {
                // console.log('open chat')
            }
        },
    }
</script>

<style lang="scss">
.header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    background: #F8F7FA;
    border-bottom: 1px solid #E5E6E9;

    &__title {
        width: 180px;
        height: 20px;
        background: url(../../assets/Main-logo.svg);
        background-size: cover;
        background-position: center;
        cursor: pointer;
    }

    &__user-events {
        width: 40px;
        height: 40px;
        border: 1px solid #686F88;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
        cursor: pointer;

        img {
            width: 20px;
            height: 20px;
        }
    }
    &__menu {
        width: 250px;
        font-size: 16px;

        &__authorization {
            width: 100%;
        }

        .v-expansion-panels {
            border: none;
        }
        .v-expansion-panel {
            border: none;
            margin: 0;

            &::before {
                content: none;
            }
            &::after {
                margin-left: 26px;
            }
        }
        .v-expansion-panel-header {
            min-height: 48px !important;
            padding: 0 16px;
            font-size: 16px;
        }
        .v-expansion-panel-content__wrap {
            padding: 0 16px;
        }
    }
    &__full-menu-switch {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        > .v-input {
            margin: 0;
        }
    }

    &__buttons {
        display: flex;
    }
    &__button {
        width: 40px;
        height: 40px;
        background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%), #FFFFFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        cursor: pointer;
        &__wrap {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__icon {
            width: 16px;
            height: 16px;
        }
    }
}
</style>