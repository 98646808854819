import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entity: {},
        entities: []
    },

    mutations: {
        CLEAR_ENTITY(state) {
            state.entity = {
                trip: null,
                name: '',
                phone: '',
                email: '',
                comment: '',
                personalDataAgree: true
            };
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/front/feedbacks', { params: { filter, sorting } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async fetchOrganizer({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/front/feedbacks/organizer', { params: { filter, sorting } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async save({ commit, state }) {
            await axios.post('/front/feedbacks', state.entity);
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/front/feedbacks/${ id }`);
        },
    },

    getters: {}
}
