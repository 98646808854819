import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {}
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {
                user: '',
                trip: '',
                picture: ''
            };
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null, limit = null, page = null }) {
            const { data } = await axios.get('/front/photos', { params: { filter, sorting, limit, page } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/front/photos/${ id }`);
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            let response = {};
            if(state.entity._id) {
                response = await axios.put(`/front/photos/${ state.entity._id }`, state.entity);
            } else {
                response = await axios.post('/front/photos', state.entity);
            }
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/front/photos/${ id }`);
        },
    },

    getters: {}
}
