<template>
    <div v-show="shown" class="preloader__backdrop">
        <v-progress-circular
            class="loader"
            indeterminate
            color="primary"
        ></v-progress-circular>
    </div>
</template>

<script>
    export default {
        name: 'Preloader',
        data: () => ({
            waitingShown: false,
            shown: false
        }),
        mounted() {
            let this_ = this;
            this_.$root.$on('preloaderShow', async () => {
                this_.waitingShown = true;
                if(this_.waitingShown)
                    this_.shown = true;
            });
            this_.$root.$on('preloaderHide', async () => {
                await new Promise(resolve => setTimeout(resolve, 500));
                this_.waitingShown = false;
                this_.shown = false;
            });
        },

    };
</script>

<style lang="scss">
    .preloader__backdrop {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(255, 255, 255);
        z-index: 15000;
    }
    .loader {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 50%;
        left: 50%;
    }
</style>
