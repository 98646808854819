import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#DA0A63',
                secondary: '#b0bec5',
                accent: '#da0a63',
                error: '#ff5252',
            },
        },
    },
});
