import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        types: [],
        countries: [],
        cities: []
    },

    mutations: {
        SET_STATE(state, payload) {
            for(const code in payload) {
                state[code] = payload[code];
            }
        },
    },

    actions: {
        async fetch({ commit }) {
            const { data } = await axios.get('/common/dictionaries');
            commit('SET_STATE', data);
            return data;
        }
    },

    getters: {}
}
