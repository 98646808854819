import moment from "../plugins/moment";

export const isMobile = () => {
    return window.innerWidth <= 768;
};

export const SplideSlideCounter = (widthItem, widthBlock, margin = 0) => {
    return Math.floor(Math.min(widthBlock, (window.innerWidth - margin)) / widthItem)
}

export const imageSrc = (picture = '') => {
    return picture ? `${process.env.VUE_APP_API_URL_PREFIX}${window.location.hostname.replace('admin.', '')}${process.env.VUE_APP_STATIC_URL_POSTFIX}/${ picture.replace(/\\/g, '/') }` : '';
};

export const checkUserProfile = (user = {}) => {
    return (user._id && user.firstname && user.lastname && user.avatar && user.about);
};

export const lastDigitToWord = (digit)  => {
    const lastFigure = parseInt(digit % 10);
    if(process.env.VUE_APP_SITE_LANGUAGE === 'ru') {
        if (digit >= 11 && digit < 15) {
            return 'дней';
        }
        else {
            if (lastFigure == 1) return 'день';
            if (lastFigure > 1 && lastFigure < 5) return 'дня';
            if (lastFigure == 0 || lastFigure >= 5) return 'дней';
        }
    } else {
        if (digit == 1) return 'day';
        if (digit > 1) return 'days';
    }
    return lastFigure
};

export const dateFormated = (date) => {
    if(process.env.VUE_APP_SITE_LANGUAGE === 'ru') {
        return moment(date).format('DD.MM.YYYY');
    }
    return moment(date).format('MMM DD, YYYY');
};

export const shortDateFormated = (date) => {
    if(process.env.VUE_APP_SITE_LANGUAGE === 'ru') {
        return moment(date).format('DD.MMM');
    }
    return moment(date).format('MMM DD');
};

export const priceFormated = (price, currency) => {
    return new Intl.NumberFormat('ru-RU').format(price) 
    + {
        'EUR': '&#8364;', 
        'USD': '&#36;',
        'RUB': '&#8381;',
    }[currency]
};

export const getDomainFromUrl = (url) =>  {
    let result, match;
    if (match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im)) {
        result = match[1]
        if (match = result.match(/^[^\.]+\.(.+\..+)$/)) {
            result = match[1]
        }
    }
    return result;
};

export const translit = (word = {}) => {
    var converter = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i',
        'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
        'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
        'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch',
        'ш': 'sh', 'щ': 'sch', 'ь': '', 'ы': 'y', 'ъ': '',
        'э': 'e', 'ю': 'yu', 'я': 'ya'
    };

    word = word.trim().toLowerCase();

    var answer = '';
    for (var i = 0; i < word.length; ++i ) {
        if (converter[word[i]] == undefined){
            answer += word[i];
        } else {
            answer += converter[word[i]];
        }
    }

    answer = answer.replace(/[^-0-9a-z]/g, '-');
    answer = answer.replace(/^\-|-$/g, '-'); 
    answer = answer.replace(/[-]+/g, '-');
    answer = answer.replace(/\-/g, ' ');
    answer = answer.trim();
    answer = answer.replace(/ /g, '-');
    return answer;
};