import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entity: {},
        organizers: [],
    },

    mutations: {
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        SET_ORGANIZERS(state, payload) {
            state.organizers = payload;
        },
    },

    actions: {
        async fetchBySocial({ commit }, { social }) {
            const { data } = await axios.get(`/front/users/social/${social}`);
            commit('SET_ENTITY', data);
            return data;
        },
        async organizers({ commit }) {
            const { data } = await axios.get('/front/users/organizers');
            commit('SET_ORGANIZERS', data);
            return data;
        },
    },

    getters: {}
}