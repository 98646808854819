import axios from '../../plugins/axios';
import { CHAT_MESSAGE_STATUS } from '@/vars';

export default {
    namespaced: true,

    state: {
        chats: [],
        chat: {},
        messages: []
    },

    mutations: {
        SET_CHATS(state, payload) {
            state.chats = payload;
        },
        SET_CHAT(state, payload) {
            state.chat = payload;
        },
        SET_MESSAGES(state, payload) {
            state.messages = payload;
        },
        ADD_MESSAGE(state, payload) {
            state.messages.push(payload);
        },
        CLEAR_ENTITY(state) {
            state.chats = [];
            state.chat = {};
            state.messages = [];
        }
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/front/chats', { params: { filter, sorting } });
            commit('SET_CHATS', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/front/chats/${ id }`);
            commit('SET_CHAT', data);
        },
        async create({ commit }, { trip_id, user_1, user_2 }) {
            const { data } = await axios.post('/front/chats/', { trip_id, user_1, user_2 });
            return data;
        },
        async fetchMessages({ commit }, { chat_id }) {
            const { data } = await axios.get(`/front/chats/${ chat_id }/messages`);
            commit('SET_MESSAGES', data);
            return data;
        },
        async sendMessage({ commit, dispatch }, { chat_id, user_id, content, file, replyMessage }) {
            const { data } = await axios.post(`/front/chats/${ chat_id }/messages`, {
                chat_id,
                user_id,
                text: content,
            });
            commit('ADD_MESSAGE', data);
            dispatch('fetchMessages', { chat_id });
        },
        async editMessage({ dispatch }, { chat_id, message_id, content }) {
            await axios.put(`/front/chats/${ chat_id }/messages/${ message_id }`, {
                text: content,
            });
            dispatch('fetchMessages', { chat_id });
        },
        async deleteMessage({ dispatch }, { chat_id, message_id, content }) {
            await axios.delete(`/front/chats/${ chat_id }/messages/${ message_id }`);
            dispatch('fetchMessages', { chat_id });
        }
    },

    getters: { }
}
