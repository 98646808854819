import Vue from 'vue'
import YmapPlugin from 'vue-yandex-maps'

Vue.use(YmapPlugin, {
    apiKey: process.env.VUE_APP_YANDEX_MAP_API_KEY,
    lang: process.env.VUE_APP_SITE_LANGUAGE === 'en' ? 'en_US' : 'ru_RU',
    coordorder: 'latlong',
    enterprise: false,
    version: '2.1'
})
