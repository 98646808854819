<template>
    <v-dialog v-model="shown" max-width="400px">
        <div class="popup-login" v-if="translates && translates[langUrl]">
            <div class="popup-login__top">
                <div class="popup-login__top__title">{{ pageTitle }}</div>
                <div class="popup-login__top__close" @click="close"></div>
            </div>
            <template v-if="step === 'phoneTextField'">
                <div class="popup-login__input" style="justify-content: space-between;">
                    <ValidationObserver ref="validator_1" slim>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BasePhoneNumberInput
                                v-model="phone"
                                :error-messages="errors[0]"
                                @update="number($event)"
                                @keyup.enter="login"
                            />
                        </ValidationProvider>
                    </ValidationObserver>
                    <v-spacer />
                    <BaseButton 
                        class="button-fill"
                        style="width: 100%;"
                        @click="login">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                </div>
            </template>
            <template v-if="step === 'phoneConfirm'">
                <div class="popup-login__text">{{ translates[langUrl].nuber_part_1[lang] }} {{ phoneWithCode }} {{ translates[langUrl].nuber_part_2[lang] }}</div>
                <div class="popup-login__input">
                    <ValidationObserver ref="validator_2" slim>
                        <ValidationProvider v-slot="{ errors }" rules="required|code">
                            <LabelTextField
                                v-model="code"
                                pattern="\d*"
                                :label="translates[langUrl].label_code[lang]"
                                counter="4"
                                :error-messages="errors[0]"
                                @keyup.enter="confirmPhone"
                                autocomplete="off"
                            />
                        </ValidationProvider>
                        <div v-if="secondsLeft" class="popup-login__send-code">{{ translates[langUrl].button_codeRepeat_part_1[lang] }} <span class="popup-login__send-code__timer">{{ translates[langUrl].button_codeRepeat_part_2[lang] }} {{ prettyCounter }}</span></div>
                        <div v-else class="popup-login__send-code_active" @click="phoneRepeatSubmit">{{ translates[langUrl].button_codeRepeat_part_1[lang] }}</div>
                    </ValidationObserver>
                    <BaseButton
                        style="width: 100%"
                        class="button-fill"
                        @click="confirmPhone">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                </div>
            </template>
            <template v-if="step === 'emailTextField'">
                <div class="popup-login__input">
                    <ValidationObserver ref="validator_3" slim>
                        <ValidationProvider v-slot="{ errors }" rules="required|email">
                            <LabelTextField
                                :label="translates[langUrl].label_email[lang]"
                                :error-messages="errors[0]"
                                v-model="email"
                                @keyup.enter="saveEmail"
                            />
                        </ValidationProvider>
                    </ValidationObserver>
                    <div class="popup-login__buttons">
                        <BaseButton
                            class="blogger-registration__form__button"
                            @click="close">{{ translates[langUrl].button_skip[lang] }}</BaseButton>
                        <BaseButton
                            class="button-fill blogger-registration__form__button"
                            @click="saveEmail">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                    </div>
                </div>
            </template>
            <template v-if="step === 'emailConfirm'">
                <div class="popup-login__text">{{ translates[langUrl].email_part_1[lang] }} {{ email }} {{ translates[langUrl].email_part_2[lang] }}</div>
                <div class="popup-login__input">
                    <ValidationObserver ref="validator_4" slim>
                        <ValidationProvider v-slot="{ errors }" rules="required|code">
                            <LabelTextField
                                v-model="emailCode"
                                pattern="\d*"
                                :label="translates[langUrl].label_emailCode[lang]"
                                counter="4"
                                @keyup.enter="confirmEmail"
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                        <div v-if="secondsLeft" class="popup-login__send-code">{{ translates[langUrl].button_codeRepeat_part_1[lang] }} <span class="popup-login__send-code__timer">{{ translates[langUrl].button_codeRepeat_part_2[lang] }} {{ prettyCounter }}</span></div>
                        <div v-else class="popup-login__send-code_active" @click="emailRepeatSubmit">{{ translates[langUrl].button_codeRepeat_part_1[lang] }}</div>
                    </ValidationObserver>
                    <div class="popup-login__buttons">
                        <BaseButton
                            class="blogger-registration__form__button"
                            @click="close">{{ translates[langUrl].button_skip[lang] }}</BaseButton>
                        <BaseButton
                            class="button-fill"
                            @click="confirmEmail">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                    </div>
                </div>
            </template>
            <template v-if="step === 'userInfo'">
                <div class="popup-login__input">
                    <ValidationObserver ref="validator_5" slim>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <LabelTextField
                                :label="translates[langUrl].firstname[lang]"
                                :error-messages="errors[0]"
                                v-model="firstname"
                            />
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <LabelTextField
                                :label="translates[langUrl].lastname[lang]"
                                :error-messages="errors[0]"
                                v-model="lastname"
                            />
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="social|social">
                            <LabelTextField
                                :class="{ 'error--text': serverErrors.social }"
                                :details="serverErrors.social"
                                v-model="social"
                                :label="translates[langUrl].social[lang]"
                                :error-messages="errors[0] || serverErrors.social"
                                :counter="100"
                                @keyup.enter="saveUser"
                            />
                        </ValidationProvider>
                    </ValidationObserver>
                    <div class="popup-login__buttons">
                        <BaseButton
                            class="blogger-registration__form__button"
                            @click="close">{{ translates[langUrl].button_skip[lang] }}</BaseButton>
                        <BaseButton
                            class="button-fill blogger-registration__form__button"
                            @click="saveUser">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                    </div>
                </div>
            </template>
        </div>
    </v-dialog>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import BasePhoneNumberInput from '../../components/common/BasePhoneInput.vue';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';

    import LabelTextField from '../../components/common/LabelTextField.vue';
    import BaseButton from '../../components/common/BaseButton.vue';

    export default {
        name: 'PopupLogin',
        components: {
            ValidationProvider,
            ValidationObserver,
            LabelTextField,
            BaseButton,
            BasePhoneNumberInput
        },
        data: () => ({
            phone: '',
            phoneWithCode: '',
            code: '',
            email: '',
            emailCode: '',
            firstname: '',
            lastname: '',
            social: '',
            step: 'phoneTextField',
            steps: [
                'phoneTextField',
                'phoneConfirm',
                'emailTextField',
                'emailConfirm',
                'userInfo'
            ],
            serverErrors: {
                social: ''
            },
            shown: false,
            secondsLeft: 0,
            intervalId: undefined,
            langUrl: 'authorization'
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            prettyCounter() {
                if (this.secondsLeft < 1) {
                    return '';
                }
                const minutes = Math.floor(this.secondsLeft / 60);
                const seconds = this.secondsLeft - minutes * 60;

                return `${minutes}:${seconds >= 10 ? seconds : `0${seconds}`}`;
            },
            pageTitle() {
                return {
                    'phoneTextField': this.translates ? this.translates[this.langUrl].pageTitle_phoneTextField[this.lang] : '',
                    'phoneConfirm': this.translates ? this.translates[this.langUrl].pageTitle_phoneConfirm[this.lang] : '',
                    'emailTextField': this.translates ? this.translates[this.langUrl].pageTitle_emailTextField[this.lang] : '',
                    'emailConfirm': this.translates ? this.translates[this.langUrl].pageTitle_emailConfirm[this.lang] : '',
                    'userInfo': this.translates ? this.translates[this.langUrl].pageTitle_userInfo[this.lang] : ''
                }[this.step];
            }
        },
        mounted() {
            let this_ = this;
            this_.$root.$on('popupLoginShow', async () => {
                this_.shown = true;
            });
            this_.$root.$on('popupLoginHide', () => {
                this_.shown = false;
            });
        },
        methods: {
            startTimer() {
                if (this.intervalId) {
                    clearInterval(this.intervalId);
                }
                this.secondsLeft = 60;
                this.intervalId = setInterval(() => {
                    if (this.secondsLeft > 0) {
                        this.secondsLeft -= 1;
                    } else {
                        clearInterval(this.intervalId);
                    }
                }, 1000);
            },
            async login() {
                const valid = await this.$refs['validator_1'].validate();
                if(valid) {
                    await store.dispatch('auth/login', { phone: this.phoneWithCode });
                    this.step = 'phoneConfirm';
                }
                await this.startTimer();
            },
            async phoneRepeatSubmit() {
                this.startTimer();
                await store.dispatch('auth/login', { phone: this.phoneWithCode });
            },
            async confirmPhone() {
                const valid = await this.$refs['validator_2'].validate();
                if(valid) {
                    await store.dispatch('auth/confirm', { code: this.code });
                    await store.dispatch('auth/fetch');
                    await store.dispatch('events/summary');
                    await store.dispatch('events/fetch', {});
                    if(!this.user.registrationComplete) {
                        if(!this.user.emailConfirmed) {
                            this.step = 'emailTextField';
                        } else {
                            this.step = 'userInfo';
                        }
                    } else {
                        await this.close();
                        this.$root.$emit('authorized');
                    }
                }
            },
            async saveEmail() {
                const valid = await this.$refs['validator_3'].validate();
                if(valid) {
                    await store.dispatch('auth/emailUpdate', { email: this.email });
                    await store.dispatch('auth/fetch');
                    this.step = 'emailConfirm';
                }
                await this.startTimer();
            },
            async emailRepeatSubmit() {
                this.startTimer();
                await store.dispatch('auth/emailUpdate', { email: this.email });
            },
            async confirmEmail() {
                const valid = await this.$refs['validator_4'].validate();
                if(valid) {
                    await store.dispatch('auth/emailConfirm', { code: this.emailCode });
                    await store.dispatch('auth/fetch');
                    this.step = 'userInfo';
                }
            },
            async saveUser() {
                const valid = await this.$refs['validator_5'].validate();
                if(valid) {
                    const response = await store.dispatch('auth/checkSocial', { phone: '+' + this.phone.replace(/[^\d]/g, ''), social: this.social });
                    if(response.errors) {
                        for(const error of response.errors) {
                            this.serverErrors[error.field] = error.message;
                        }
                    } else {
                        store.commit('auth/SET_USER', { ...this.user, firstname: this.firstname, lastname: this.lastname, social: this.social });
                        await store.dispatch('auth/save');
                        await store.dispatch('auth/fetch');
                        await this.close();
                        this.$root.$emit('authorized');
                    }
                }
            },
            async back() {
                if(this.steps.indexOf(this.step) > 0) {
                    this.step = this.steps[this.steps.indexOf(this.step) - 1];
                }
            },
            async close() {
                this.shown = false;
                await new Promise((resolve, reject) => setTimeout(resolve, 1000));
                this.phone = '';
                this.code = '';
                this.email = '';
                this.emailCode = '';
                this.firstname = '';
                this.lastname = '';
                this.social = '';
                this.step = this.steps[0];
            },
            number(event) {
                this.phoneWithCode = event.formattedNumber
                // console.log(this.phoneWithCode)
            }
        }
    }
</script>

<style lang="scss">
.popup-login {
    min-height: 300px;
    max-height: 400px;
    padding: 15px 20px 30px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    background: #FFFFFF;
    box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
    border-radius: 12px;
    overflow: visible;
    &__open-button {
        display: flex;
        justify-content: center;
        padding: 10px 20px;
        border: 1px solid #F1F3F5;
        box-sizing: border-box;
        border-radius: 12px;
        background: none;
        cursor: pointer
    }
    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // align-items: center;
        padding-bottom: 13px;
        border-bottom: 1px solid #EBEBEB;
        &__title {
            display: flex;
            flex-direction: column;
            width: 280px;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.02em;
            color: #273155;
        }
        &__close {
            width: 15px;
            height: 15px;
            background: url(../../assets/PlusGrey.svg);
            transform: rotate(45deg);
            margin-top: 5px;
            cursor: pointer;
        }
    }
    &__text {
        padding-top: 10px;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.02em;
        opacity: 0.5;
        // padding-bottom: 10px;
    }
    &__input {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
    }
    &__send-code {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #3A4256;
        margin: 7px auto 20px 10px;
        &__timer {
            opacity: 0.5;
        }
        &_active {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.02em;
            text-decoration: underline;
            // opacity: 0.5;
            color: #3A4256;
            margin: 7px auto 20px 10px;
            cursor: pointer;
        }
    }
    &__buttons {
        display: flex;
        justify-content: space-between;

        .base-button {
            width: 150px;
        }
    }
}
</style>