import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        main: {},
        headerMenu: {
            mainMenuAbout: {
                links: []
            },
            mainMenuBlogger: {
                links: []
            },
            mainMenuOrganizer: {
                links: []
            },
            mainMenuTrips: {
                links: []
            },
            bloggerMenu: {
                links: []
            },
            organizerMenu: {
                links: []
            },
            hiddenMenu: {
                links: []
            },
        },
        footer: {},
        questions: [],
        questionsOperator: [],
        contacts: {}
    },

    mutations: {
        SET_STATE(state, payload) {
            for(const code in payload) {
                state[code] = payload[code];
            }
        },
    },

    actions: {
        async fetch({ commit }) {
            const { data } = await axios.get('/common/page-templates');
            commit('SET_STATE', data);
            return data;
        }
    },

    getters: {}
}
