import axios from '../../plugins/axios';
import { TRIP_TYPE, DIRECTION_TYPE } from '../../vars';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {}
    },

    mutations: {
        CLEAR_ENTITIES(state) {
            state.entities = [];
            state.entitiesCount = 0;
        },
        CLEAR_ENTITY(state) {
            state.entity = {
                type: TRIP_TYPE.TOUR_LEADER,
                name: '',
                code: '',
                directionType: DIRECTION_TYPE.RUSSIA,
                host: '',
                yandexMetricaId: '',
                scripts: [],
                logo: '',
                Inst: '',
                VK: '',
                telegram: '',
                phone: '',
                mail: '',
                organizer: {
                    firstname: '',
                    lastname: '',
                    avatar: '',
                    phone: '',
                    email: '',
                    social: '',
                    about: ''
                },
                numbersDescription: '',
                days: '',
                price: 0,
                currency: process.env.VUE_APP_DEFAULT_CURRENCY,
                groupSize: '',
                sities: '',
                places: '',
                direction: [],
                pictures: [],
                mainPictureIndex: 0,
                tourOperator: '',
                groupSizeFrom: '',
                groupSizeTo: '',
                groupAgeFrom: '',
                groupAgeTo: '',
                language: '',
                hygiene: '',
                momentsDescription: '',
                moments: [
                    {
                        title: '',
                        description: ''
                    }
                ],
                PDF: '',
                mapPicture: '',
                mainPlaces: [
                    {
                        picture: '',
                        description: '',
                    }
                ],
                benefitsDescription: '',
                benefitsPicture: '',
                benefits: [
                    {
                        title: '',
                        type: '',
                        description: ''
                    }
                ],
                included: [
                    {
                        title: '',
                        description: '',
                        picture: '',
                    }
                ],
                notIncluded:[
                    {
                        title: '',
                        description: '',
                        picture: '',
                    }
                ],
                advantages: [
                    {
                        title: '',
                        description: '',
                    }
                ],
                activitiesDescription: '',
                activities: [
                    {
                        title: '',
                        picture: '',
                        description: '',
                    }
                ],
                workshops: [
                    {
                        title: '',
                        description: '',
                    }
                ],
                level: 'beginner',
                mainType: '',
                additionalTypes: [
                    ''
                ],
                tags: [],
                arrivals: [
                    {
                        start: null,
                        finish: null,
                        price: 0,
                        currency: process.env.VUE_APP_DEFAULT_CURRENCY,
                        places: null,
                        selled: null,
                        empty: null,
                        discount: null,
                        discountBefore: null,
                        gift: ''
                    },
                ],
                programs: [
                    {
                        title: '',
                        description: '',
                        pictures: [],
                    },
                ],
                accommodationsDescription: '',
                accommodations: [
                    {
                        title: '',
                        description: '',
                        mainPictureIndex: 0,
                        type: '',
                        raiting: '',
                        bookingLink: '',
                        pictures: []
                    }
                ],
                transportsDescription: '',
                transportsTypes: [
                    ''
                ],
                transports: [
                    {
                        picture: '',
                        description: '',
                    }
                ],
                community: [
                    {
                        firstname: '',
                        lastname: '',
                        photo: '',
                        review: '',
                        social: '',
                    }
                ],
                questions: [
                    {
                        question: '',
                        answer: '',
                    }
                ],
                photos: [],
                visibleBlocks: {
                    map: true,
                    numbers: true,
                    info: true,
                    photo: true,
                    mainPlaces: true,
                    moments: true,
                    benefits: true,
                    programs: true,
                    included: true,
                    accommodations: true,
                    transports: true,
                    activities: true,
                    arrivals: true,
                    community: true,
                    questions: true,
                    photos: true,
                }
            };
        },
        SET_ENTITIES(state, payload) {
            if (payload.items) {
                if (payload.page === 1 || payload.byPages) {
                    state.entities = payload.items;
                    state.entitiesCount = payload.totalCount;
                } else {
                    state.entities = [
                        ...state.entities,
                        ...payload.items
                    ];
                    state.entitiesCount = payload.totalCount;
                }
            } else {
                state.entities = payload;
                state.entitiesCount = payload.length;
            }
        },
        SET_ENTITY(state, payload) {
            state.entity = {
                ...state.entity,
                ...payload
            };
        },
        SET_ENTITY_VALUE(state, { prop_1, prop_2, prop_3, prop_4, value }) {
            if(typeof prop_4 !== 'undefined') {
                state.entity[prop_1][prop_2][prop_3][prop_4] = value;
            } else if(typeof prop_3 !== 'undefined') {
                state.entity[prop_1][prop_2][prop_3] = value;
            } else if(typeof prop_2 !== 'undefined') {
                state.entity[prop_1][prop_2] = value;
            } else if(typeof prop_1 !== 'undefined') {
                state.entity[prop_1] = value;
            } else {
                state.entity = value;
            }
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null, page = 1, itemsPerPage = 10, byPages = true }) {
            const { data } = await axios.get('/front/trips', { params: { filter, sorting, page, itemsPerPage } });
            commit('SET_ENTITIES', { ...data, page, byPages });
            return data;
        },
        async photos({ commit }, { filter = {}, sorting = null, page = 1, itemsPerPage = 10 }) {
            const { data } = await axios.get('/front/trips/photos', { params: { filter, sorting, page, itemsPerPage } });
            commit('SET_ENTITIES', { ...data, page });
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/front/trips/${ id }`);
            commit('CLEAR_ENTITY', data);
            commit('SET_ENTITY', data);
        },
        async getByCode({ commit }, { code }) {
            const { data } = await axios.get(`/front/trips/code/${ code }`);
            commit('CLEAR_ENTITY', data);
            commit('SET_ENTITY', data);
        },
        async getByHost({ commit }, { host }) {
            const { data } = await axios.get(`/front/trips/host/${ host }`);
            commit('CLEAR_ENTITY', data);
            commit('SET_ENTITY', data);
            return data;
        },
        async save({ commit, state }) {
            let response = {};
            if(state.entity._id) {
                response = await axios.put(`/front/trips/${ state.entity._id }`, state.entity);
            } else {
                response = await axios.post('/front/trips', state.entity);
                state.entity._id = response.data._id;
            }
            for(const photo of state.entity.photos) {
                if(photo.picture) {
                    if(!photo._id) {
                        response = await axios.post('/front/photos', {
                            ...photo,
                            ...{ trip: state.entity._id }
                        });
                    }
                }
            }
            return response.data;
        },
        async copy({ commit }, { id }) {
            const { data } = await axios.put(`/front/trips/copy/${ id }`);
            commit('CLEAR_ENTITY', data);
            commit('SET_ENTITY', data);
            return data;
        },
        async setStatus({ commit, state }, { id, status }) {
            await axios.put(`/front/trips/status/${ id }/${ status }`);
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/front/trips/${ id }`);
        },
        async favoriteFetch({ commit }) {
            const { data } = await axios.get('/front/trips/favorites');
            commit('SET_ENTITIES', data );
            return data;
        },
        async favoriteAdd({ commit }, { id }) {
            await axios.put(`/front/trips/favorites/${ id }`);
        },
        async favoriteDelete({ commit }, { id }) {
            await axios.delete(`/front/trips/favorites/${ id }`);
        },
    },

    getters: {}
}
