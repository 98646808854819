import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        user: {
            firstname: '',
            lastname: '',
            avatar: '',
            birthday: '',
            city: '',
            site: '',
            company: '',
            images: [],
            instagram: '',
            phone: '',
            reviews: '',
            about: '',
            email: '',
            emailConfirmed: false,
            registrationStart: true
        }, 
        auth: {}
    },

    mutations: {
        SET_USER(state, payload) {
            state.user = {
                ...state.user,
                ...payload
            };
        },
        CLEAR_USER(state) {
            state.user = {
                firstname: '',
                lastname: '',
                avatar: '',
                birthday: '',
                city: '',
                site: '',
                images: [],
                instagram: '',
                phone: '',
                reviews: '',
                about: '',
                email: '',
                emailConfirmed: false,
                registrationStart: true
            };
            localStorage.removeItem('shinytravel-token');
        },
        SET_VALUE(state, { prop, value }) {
            state.user[prop] = value;
        },
        SET_AUTH(state, payload) {
            state.auth = payload;
        },
    },

    actions: {
        async fetch({ commit }) {
            const { data } = await axios.get('/common/auth/profile');
            commit('SET_USER', data);
            return data;
        },
        async checkSocial({ commit }, { phone, social }) {
            const { data } = await axios.post('/common/auth/check-social', { phone: phone.replace(/ /g, ''), social });
            return data;
        },
        async save({ commit, state }) {
            if(state.user._id) {
                await axios.put('/common/auth/profile', state.user);
            }
        },
        async adminLogin({ commit, state }, { phone, code }) {
            const { data } = await axios.post('/common/auth/admin-login', { phone: phone.replace(/ /g, ''), code });
            commit('SET_AUTH', data);
            commit('SET_USER', {
                ...state.user, 
                ...data
            });
            localStorage.setItem('shinytravel-token', state.user.token);
            return data;
        },
        async login({ commit }, { phone, blogger = false }) {
            const { data } = await axios.post('/common/auth/login', { phone: phone.replace(/ /g, ''), blogger });
            commit('SET_AUTH', data);
            return data;
        },
        async confirm({ state, commit }, { code, login = true }) {
            const { data } = await axios.post('/common/auth/confirm', { ...state.auth, ...{ code } });
            commit('SET_USER', {
                ...state.user, 
                ...data
            });
            if (login) {
                localStorage.setItem('shinytravel-token', state.user.token);
            }
            return data;
        },
        async emailUpdate({ state, commit }, { email }) {
            const { data } = await axios.put('/common/auth/email', { email });
            commit('SET_USER', {
                ...state.user, 
                ...data
            });
            return data;
        },
        async emailConfirm({ state, commit }, { code }) {
            const { data } = await axios.post('/common/auth/email/confirm', { ...state.user, ...{ code } });
            commit('SET_USER', {
                ...state.user, 
                ...data
            });
            return data;
        },
        async logout({ commit }) {
            commit('CLEAR_USER');
        }
    },

    getters: {}
}